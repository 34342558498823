<template>
  <div>
    <p>Select the atomic orbital type that matches each of the figures on the left.</p>
    <br />
    <v-form @submit.prevent="submitResponse">
      <table>
        <tr v-for="(part, i) in parts" :key="part.imageUrl">
          <td style="width: 200px; vertical-align: top; height: 180px">
            <img :src="part.imageUrl" width="160" height="160" />
          </td>
          <td>
            <v-radio-group v-model="inputs[`input${i + 1}`]">
              <v-radio
                v-for="option in orbitalTypes"
                :key="`pt-${i + 1}-` + option.value"
                :value="option.value"
                hide-details
                :ripple="false"
              >
                <template v-slot:label>
                  <stemble-latex :content="`$\\text{${option.text}}$`" />
                </template>
              </v-radio>
            </v-radio-group>
          </td>
        </tr>
      </table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question23',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
      parts: [
        {imageUrl: '/img/assignments/orb3.png'},
        {imageUrl: '/img/assignments/orb1.png'},
        {imageUrl: '/img/assignments/orb2.png'},
      ],
      orbitalTypes: [
        {text: 's', value: 's'},
        {text: 'p', value: 'p'},
        {text: 'd', value: 'd'},
        {text: 'f', value: 'f'},
      ],
    };
  },
};
</script>
<style scoped>
.v-input {
  margin-top: 0;
}
</style>
